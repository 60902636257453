<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
  <b-badge variant="light-primary">
             {{ userData.isOutSource?'Imported Employee':'Registered Member' }}
      </b-badge>
      </h5>
      <br/>
      <div class="mt-2 mb-2"  ref="qrcode" >
      <qrcode-vue :value="vcfCode" :size="size" level="H" />
    </div> 
   
    <br/>
    <b-button
    @click="downloadQrCode"
                variant="primary"
              >
                Download
              </b-button>
Registration Date
      <small class="text-muted w-100"> {{ userData.createdAt }} </small>
    </b-card-header>

    <b-card-body>
      
      
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import QrcodeVue from 'qrcode.vue'
export default {
  directives: {
  
  },
   props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods:{
     getCanvasAsImage(){
console.log("dd");
let canvasImage = this.$refs['qrcode'];//  document.getElementByTagName('canvas').toDataURL('image/png');
console.log(canvasImage);
// this can be used to download any image from webpage to local disk
let xhr = new XMLHttpRequest();
xhr.responseType = 'blob';
xhr.onload = function () {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = 'image_name.png';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', canvasImage); // This is to download the canvas Image
  xhr.send();
}
  },
 setup(){
  
const qrcode = ref(null);

const downloadQrCode = () => {
    let canvasImage = qrcode.value.getElementsByTagName('canvas')[0].toDataURL('image/png');
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'qrcode.png';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    xhr.open('GET', canvasImage);
    xhr.send();

}

return{
  qrcode,
  downloadQrCode
 }

 },
  computed:
  {
    vcfCode(){
      var host=process.env.VUE_APP_API_URL;
       
      return  host+"/cms/vcf/"+this.userData.userguid;

    }
  },
  data(){
    return {
    
        size: 100,
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    QrcodeVue
  },
  mounted()
  {

    function downloadCanvasAsImage(){
console.log("dd");
let canvasImage = document.getElementByTagName('canvas').toDataURL('image/png');

// this can be used to download any image from webpage to local disk
let xhr = new XMLHttpRequest();
xhr.responseType = 'blob';
xhr.onload = function () {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = 'image_name.png';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  xhr.open('GET', canvasImage); // This is to download the canvas Image
  xhr.send();
}

    function selectText(element) {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function copyBlobToClipboardFirefox(href) {
    const img = document.createElement('img');
    img.src = href;
    const div = document.createElement('div');
    div.contentEditable = true;
    div.appendChild(img);
    document.body.appendChild(div);
    selectText(div);
    const done = document.execCommand('Copy');
    document.body.removeChild(div);
    return done;
  }

  function copyBlobToClipboard(blob) {
    // eslint-disable-next-line no-undef
    const clipboardItemInput = new ClipboardItem({
      'image/png' : blob
    });
    return navigator.clipboard
      .write([clipboardItemInput])
      .then(() => true)
      .catch(() => false);
  }

  function downloadLink(name, href) {
    const a = document.createElement('a');
    a.download = name;
    a.href = href;
    document.body.append();
    a.click();
    a.remove();
  }
  }
}

</script>

<style>

</style>
